<template>

  <div>
    <div class="box">
      <div style="display: flex;background-color: #f7f7f7">
        <div style="background-color: white">
          <div class="ViewTitle" >
            <div class="active" v-if="status===''" @click="statusChange('')">
              全部状态
            </div>
            <div class="activeHover" v-else @click="statusChange('')">
              全部状态
            </div>
            <div class="active" v-if="status==='0'" @click="statusChange('0')">
              已投递
            </div>
            <div class="activeHover" v-else @click="statusChange('0')">
              已投递
            </div>
            <div class="active" v-if="status==='1'" @click="statusChange('1')">
              已查看
            </div>
            <div class="activeHover" v-else @click="statusChange('1')">
              已查看
            </div>
          </div>
          <div class="messageList">
            <div class="middle">
              <div  v-if="loading===false">
                <div v-if="messageList.length">

                  <li v-for="(lists,index) in messageList" :key="index">
                    <div class="listTotle" @click="personal(lists)">
                      <div class="interview">
                        <div style="display: flex">
                          <div class="interId">
                            <el-avatar :size="35" :src="baseURL +lists.interviewAvatar"></el-avatar>
                          </div>
                          <div class="name">
                            {{lists.interviewName}}

                          </div>
                          <div class="name">
                            {{lists.interviewDuties}}
                          </div>
                        </div>
                        <div>
                          <div class="deliver" v-if="lists.status==='0'">已投递...</div>
                          <div class="scan" v-if="lists.status==='1'">已查看...</div>
                        </div>

                      </div>
                      <div class="detail">
                        <div>
                          <div>
                        <span class="positionName">
                          {{ lists.positionName }}【{{ lists.positionAddress }}】</span
                        >
                          </div>
                          <div style="display: flex">
                            <div class="salaryName">
                              {{ lists.positionSalary }}</div
                            >
                            <div class="jobStyle">

                              <span class="jobStyle1">{{ lists.positionTime }}</span>
                              <span class="jobStyle2">{{lists.positionEducation }}</span>
                              <span class="jobStyle2">{{lists.positionCategory}}</span>
                            </div>
                          </div>

                        </div>
                        <div class="comButton">
                          <div class="comLogo">
                            <img :src="baseURL + lists.companyAvatar" alt="" />
                          </div>
                          <div class="companyName">
                            <div class="name1">{{ lists.companyName }}</div>
                            <div style="display: flex;margin-top: 15px">
                              <div class="jobStyle1" style="display: flex">
                                <div  v-for="(item, index) in lists.companyIndustry" :key="item.id">
                                  <span>{{ item }}</span>
                                  <span v-if="index < lists.companyIndustry.length - 1">、</span>
                                </div>
                              </div>

                              <div class="jobStyle2">{{ lists.companyFinancing }}</div>
                              <div class="jobStyle2">{{ lists.companyScale }}</div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <div v-if="messageList.length !== 0" class="pagination-container">
                    <el-pagination
                        v-model="page"
                        :current-page="page"
                        :page-size='size'
                        :page-sizes="[5, 8, 10, 15]"
                        :total="total"
                        background
                        layout="sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                  </div>
                </div>
                <div class="noData" style="width: 950px" v-else>
                  <img src="@/assets/none.png" alt="" />
                  暂无数据
                </div>
              </div>
              <div v-else class="pagination-container">
                <div class="shake">
                  <img  src="../../assets/loading.png" alt="" />
                </div>
                <div id="animatedText" style="line-height: 36px;margin-top: 15px; font-size: 16px;text-align: center">
                  {{ displayedText }}
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="jianli" v-if="userId">
          <keep-alive>
            <PersonalLittle></PersonalLittle>
          </keep-alive>

        </div>
      </div>
    </div>
  </div>

</template>


<script>

import {shareUrl} from "@/utils/vxshare";
import PersonalLittle from "@/components/PersonalLittle.vue";
export default {

  name: "",

  props: [],

  components: {PersonalLittle},

  data() {

    return {
      messageList: [],
      page: 1,
      size:8,
      baseURL: this.$utils.baseURL,
      total:"",
      positionInterviewId:'',
      userId:localStorage.getItem("userId"),
      loading:true,
      displayedText: '',
      index: 0,
      animationInterval: null,
      // userId:2,
      status:'',
      languageSelect:'',
      interView:[],
      text: '正在加载中，请稍候...',
    };

  },

  methods: {
    statusChange(item){
      this.status=item
      this.getData()
    },
    // 详情
    personal(list){
      console.log(list)
      this.$router.push({
        path: "/positionDetail",
        query: {
          positionId:list.positionId,
        },
      });
      // window.location.reload();

    },
    handleSizeChange(val) {
      this.size = val;
      // console.log(`每页 ${val} 条`+this.size)
      // console.log('gengxin')
      window.scrollTo(0, 0);
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      // this.handleScroll();
      // console.log(`当前页: ${val}`+this.page)
      window.scrollTo(0, 0);
      this.$forceUpdate(); //强制重新渲染分页器组件
      this.getData();
    },
    getData(){
      this.$axios
          .get("/system/deliveryStatus/list", {
            params: {
              userId:this.userId,
              status:this.status,
              pageNum:this.page,
              pageSize:this.size
            },
            headers: {
              Authorization: this.token,
            },
          })
          .then(async (res) => {
            this.messageList = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组
            this.total = res.data.total
            this.positionInterviewId = res.data.rows.positionInterviewId
            // console.log(res.data.rows[0].interviewName[0])

            for (var i = 0; i < this.messageList.length; i++) {
              // console.log(this.messageList[i].companyIndustry);
              this.messageList[i].companyIndustry = JSON.parse(this.messageList[i].companyIndustry)



              if(this.messageList[i].positionTime!==null){
                this.messageList[i].positionTime = await this.getTime(this.messageList[i].positionTime);
              }
              if(this.messageList[i].positionEducation!==null){
                this.messageList[i].positionEducation = await this.getEducation(this.messageList[i].positionEducation);
              }
              if(this.messageList[i].positionCategory!==null){
                this.messageList[i].positionCategory = await this.getCategory(this.messageList[i].positionCategory);

              }


              // console.log(this.messageList[i].companyIndustry[0]);
              this.handleSex(this.messageList[i])
            }
            this.loading=false
          })
          .catch((error) => {
            console.log(error);
          });
    },
    handleSex(item){
      if(item.sex!==null){
        if(item.sex==="1"){
          item.interviewName=item.interviewName[0]+"先生"
        }
        else{
          item.interviewName=item.interviewName[0]+"女士"

        }
        console.log(item.interviewName)
      }

    },
    getTime(item) {
      return this.$axios
          .get("/system/time/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getEducation(item) {
      return this.$axios
          .get("/system/education/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getCategory(item) {
      return this.$axios
          .get("/system/position_category/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    startAnimation() {
      this.animationInterval = setInterval(() => {
        if (this.index < this.text.length) {
          this.displayedText += this.text[this.index];
          this.index++;
        } else {
          this.index = 0;
          this.displayedText = '';
        }
      }, 300); // 控制字符出现的速度，数值越小，速度越快
    },
  },

  created() {
    this.getData()
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);

  },
  mounted() {

    this.startAnimation();
  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.jianli {
  margin:0 0 0 20px;
  width: 260px;
  height: 267px;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.middle{
  overflow:hidden;
  margin:0 15px;
  width: 890px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  list-style: none;


  .pagination-container {
    position: sticky;
    margin: 20px auto;
    padding: 25px;
    bottom: 15px;
    width: 99%;
    text-align: center;
    .shake {
      width: 150px;
      height: 150px;
      margin: 15px auto 0 auto;
      padding: 0;
      overflow: hidden; /* 确保超出部分被裁剪 */
      position: relative; /* 使图片的定位相对于其父元素 */
    }

    .shake img {
      position: absolute;
      top: 15px;
      left: 0;
      height: 90%;
      width: 90%;
      animation: shake 1s ease-in-out infinite; /* 调整时间、缓动函数和循环次数 */
    }

    @keyframes shake {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px); /* 图片上下移动的距离 */
      }
    }
    ::v-deep .el-pager li {
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;
    }
    ::v-deep .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;

    }
    ::v-deep .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;
    }
  ;
  }
  .listTotle {
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
    margin-top: 20px;
    width: 100%;
    border-radius: 5px;
    height: fit-content;
    //background:linear-gradient(to right, rgb(255, 248, 250) 50%, #eaeef8 100%);;
    //box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border:rgba(0, 0, 0, 0.05) 1px solid ;
    opacity: 1;

    .interview{
      display: flex;
      align-items: center;
      justify-content:space-between;
      //justify-content: center;
      height: 56px;
      padding: 0 55px 0 15px;
      background:linear-gradient(to right, #fff9f7 50%, #f5f6fa 100%);;
      width: 100%;
      .name{
        margin: 0 0 0 15px;
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      .deliver{
        color: #bf0022;

      }
      .scan{
        color: #64a205;
      }
    }
    .detail {
      padding: 20px 20px 20px 20px;
      display: flex;
      cursor: pointer;
      position: relative;

      .positionName {
        width: 64px;
        height: 16px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 600;
        color: #333333;
        line-height: 16px;
      }

      .positionName:hover {
        color: #bf0022;
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
      .salaryName {
        margin:16px 12px 0 0;
        width: fit-content;
        height: 21px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        color: #bf0022;
        line-height: 22px;
      }

      .jobStyle {
        display: flex;
        margin-top: 16px;
        width: 18vw;

      }

      .jobStyle1 {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        border-radius: 6px;
        background-color:#f8f8f8;
        padding: 3px 10px;
      }

      .jobStyle1:hover {
        color: #bf0022;
        transform: scale(1.2);
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }

      .jobStyle2 {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        background-color:#f8f8f8;
        border-radius: 6px;
        padding: 3px 10px;
        margin-left: 12px;
      }

      .jobStyle2:hover {
        color: #bf0022;
        transform: scale(1.2);
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }


      .comButton {
        position: absolute;
        left:450px;
        display: flex;
        top: 15px;
        text-align: center;
        .comLogo {
          width: 35px;
          height: 35px;
          border: rgba(100, 98, 98, 0.1) 1px solid;
          border-radius: 4px;
          opacity: 1;
        }

        .comLogo:hover {
          transform: scale(1.1);
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }
        .companyName {
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          margin-left: 8px;

          .spanthis{
            width: 1px;
            margin: 3px 0 auto 16px;
            height: 16px;
            background-color: #e0dddd;
          }
          .name1 {
            width: 200px;
            height: 22px;

            font-size: 14px;
             font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #050505;
            text-align: left;
            line-height: 22px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .name1:hover {
            color: #bf0022;
            transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          }

          .name2 {
            height: fit-content;
            margin: 12px 15px 0 0;
            font-size: 13px;
            border: 9px;
             font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #666666;
            line-height: 22px;
            padding: 3px 8px;
            background-color: #f8f8f8;
          }
          .name2:hover {
            color: #bf0022;
            transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          }
        }
        .companyBut {
          margin-top: 10px;
          width: 180px;
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: white;
          color: #bf0022;
          border-radius: 23px;
          opacity: 1;
          border: 1px solid #fdfdfd;
        }

        .companyBut:hover {
          /* 此处是鼠标悬浮时的样式 */
          color: rgba(100, 98, 98);
          background: #fdfdfd;
          border:  #416dfd  1px solid;
          /* 你可以添加其他的样式以突出悬浮状态 */
        }

        ::v-deep .el-button {
          padding: 5px 10px;
        }
      }
    }

  }
  .listTotle:hover {
    transform:translateY(-10px);

  }
}
.box {
  width: 100%;
  background: #fff;
  .ViewTitle {
    height: 80px;
    border-bottom: 1px solid #ebebeb;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
    display: flex;
    .active{
      cursor: pointer;
      background-color: #ffefe5;
      padding: 5px 10px;
      color: #bf0022;
      font-weight: bold;
      border-radius: 8px;
      height: fit-content;
      margin: 25px 15px 0 0;
    }
    .activeHover{
      cursor: pointer;
      padding: 5px 10px;
      color:black;
      border-radius: 8px;
      height: fit-content;
      margin: 25px 15px 0 0;
    }
    .activeHover:hover{
      cursor: pointer;
      padding: 5px 10px;
      color: #bf0022;
      border-radius: 8px;
      height: fit-content;
      margin: 25px 15px 0 0;
    }
  }

  .messageList {
    display: flex;
    width: 920px;
    padding-bottom: 48px;
    border-radius: 5px;
    min-height: 380px;
    .list {
      padding: 0 30px;
      .item {
        display: flex;
        align-items: center;
        padding: 17px 22px;
        border: 1px solid #ebebeb;
        margin-top: 16px;
        .icon {
          width: 48px;
          height: 48px;
          margin-right: 12px;
        }
        .text {
          flex:1;
          .title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            cursor: pointer;
            max-width: 100%;
            width: fit-content;
            .step {
              .el-badge__content.is-dot {
                background-color: #bf0022 !important;
              }
            }
          }
          .flex {
            display: flex;
            font-size: 12px;
            align-items: center;
            margin-top: 6px;
            .time {
              color: #999999;
              margin-right: 11px;
            }
            span {
              color: #bf0022;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
      }

      .el-pagination {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
}
</style>
