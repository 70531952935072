<template>
<div>
  <div class="personal">
    <div class="info1">
      <div class="left">
        <img
            :src="reallyavator ? baseURL + reallyavator : avator"
        />
      </div>
      <div class="right">
        <div style="display: flex">
          <div class="name">{{nickName}}</div>
          <div v-if="sex==='1'" class="sex1"></div>
          <div v-else class="sex2"></div>
        </div>
        <div class="pronouce">找工作快人一步</div>
      </div>
    </div>
    <div class="info2">
      <div @click="scan()" class="scan">
        <div class="scan1">{{see }}</div>
        <div class="scan2">&nbsp;&nbsp;&nbsp;我看过</div>
      </div>
      <div @click="pass()" class="scan">
        <div class="scan1">{{Passtotal}}</div>
        <div class="scan2">我的投递</div>
      </div>
      <div @click="collect()" class="scan">
        <div class="scan1">{{ Collecttotal }}</div>
        <div class="scan2">我的收藏</div>
      </div>
    </div>
    <div class="info3"></div>
    <div class="info4">
      <div class="zaixian" @click="zaixian"></div>
      <div class="fujian" @click="fujian"></div>
    </div>


  </div>
  <div @click="zhanguan" class="advertisement"></div>
</div>


</template>


<script>

export default {

  name: "",

  props: [],

  components: {},

  data() {

    return {
      baseURL: this.$utils.baseURL,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      userId: this.$store.state.userId,
      // userId:80,
      avator: require("@/assets/avator.png"),
      Collecttotal:0,
      Passtotal:0,
      see:0,
      Scan:0,
      sex:'',
      nickName:'',
      reallyavator:'',
      token:localStorage.getItem("access_token")
    }

  },
  created() {
    this.getUser()
    setInterval(() => {
      this.getdata()
      this.getPasstotal()
      this.getsee()
    }, 2000);
      // this.getdata()
      // this.getPasstotal()
      // this.getsee()

  },

  methods: {
    getUser() {
      this.$axios.get("/system/job_user/"+this.userId,{
        headers: {
          Authorization: this.token,
        },
          }
      ).then((res) => {
        // console.log("icjiosdjoiadno")
        // console.log(res.data.data)
        this.nickName=res.data.data.nickName
        this.reallyavator=res.data.data.avatar
        this.sex=res.data.data.sex
        // console.log("cdf",this.nickName)

      }).catch(err=>{
        console.log(err)
      });
    },
    // 收藏
    getdata(){
      this.$axios.get("/system/position_collect/count/"+this.userId, {
            headers: {
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.Collecttotal=res.data

          })
          .catch((error) => {
            console.log(error);
          });
    },
    // 投递
    getPasstotal(){
      this.$axios
          .get("/system/deliveryStatus/deliverCount", {
            params: {
              userId:this.userId,
            },
            headers: {
              Authorization: this.token,
            },
          })
          .then((res) => {
              this.Passtotal=res.data.data
          })
          .catch((error) => {
            console.log(error);
          });
    },
    // 浏览
    getsee(){
      this.$axios
          .get("/system/brow/count", {
            params: {
              userId:this.userId,
            },
            headers: {
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log("liulan")
            console.log(res.data)
            this.see=res.data.data
          })
          .catch((error) => {
            console.log(error);
          });
    },
    collect(){
      this.$router.push("/jobCollect")
    },
    scan(){
      this.$router.push("/jobCommunicate")
    },
    pass(){
      this.$router.push("/jobCommit")
    },
    zaixian(){
      this.$router.push("/AIresume")
    },
    fujian(){
      this.$router.push("/Info")

    },
    zhanguan(){
      window.open("https://cn.oilgasmall.com/")
    },
  },

  mounted() {


  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.personal{
  width: 260px;
  height: 267px;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
  border-radius: 5px;
  opacity: 1;
  padding:20px 22px;
  .info1{
    display: flex;
    .left{
      width: 50px;
      height: 50px;
      border-radius: 45%;
      opacity: 1;
    }
    .right{
      margin-left: 12px;
      .name{
        height: 32px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #333333;
        line-height: 32px;
      }
      .sex1{
        width: 14px;
        height: 14px;
        margin:10px auto 8px 4px;
        background-image: url("../assets/girl.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat

      }
      .sex2{
        width: 14px;
        height: 14px;
        margin:10px auto 8px 4px;
        background-image: url("../assets/boy.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat

      }
      .pronouce{
        height: 18px;
        font-size: 12px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #666666;
        line-height: 18px;
      }

    }
  }
  .info2{
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
    margin-top: 32px;
    .scan1{
      cursor: pointer;
      height: 20px;
      font-size: 24px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      text-align: center;
    }
    .scan2{
      cursor: pointer;
      height: 32px;
      font-size: 12px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #333333;
      line-height: 32px;
    }

    .scan:hover{
      .scan1{
        color: #ef0e0e;
      }
      .scan2{
        color: #ef0e0e;
      }
    }
  }
  .info3{
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: #F5F5F5;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  .info4{
    margin-top: 18px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 8px;
    .zaixian{
      cursor: pointer;
      height: 63px;
      border-radius: 5px;
      opacity: 1;
      background-image: url("../assets/zaixian.png");
      background-size:contain;
      background-position:center;
      background-repeat:no-repeat
    }
    .fujian{
      cursor: pointer;
      height: 63px;
      border-radius: 5px;
      opacity: 1;
      background-image: url("../assets/fujian.png");
      background-size:contain;
      background-position:center;
      background-repeat:no-repeat
    }
  }
}
.advertisement {
  margin-top: 15px;
  width: 260px;
  height: 170px;
  border-radius: 5px;
  cursor: pointer;
  background-image: url("../assets/advertisement.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
  //border: black 10px solid;
  .logo {
    opacity: 12;
    width: 50%;
    height: 100%;
    //background-image: url("../../assets/login.png");
    //background-size: contain;
    //background-position: center;
    //background-repeat: no-repeat;
    //background-color: transparent; /* 这里设置背景颜色为透明 */
  }
  .title {
    line-height: 30px;
    color: white;
    text-align: right;

    top: 15px;
    position: absolute;
    right: 15px;
    font-weight: bold;
    font-size: 22px;
  }
}
</style>
